import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import BackgroundImage from '../components/BackgroundImage'

export default ({ cat, height }) => (
  <Container className={`cat-teaser`}>
    <Link to={`/tips/category/${cat.slug}/`}>

      <BackgroundImage height={height} imageData={cat.image.fluid}>
        <Overlay className="overlay">  
          <Heading>{cat.name}</Heading>
         </Overlay>   
      </BackgroundImage>

      </Link>
  </Container>
)


const Container = styled.div`
 a {
   text-decoration: none;
 }

 section {
    &:hover {
      .overlay {
        background: rgba(0,0,0, 0.5);
      }
    }  
  }
`;

const Overlay = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 30px;
  width: 100%;
  height: 100%;
  background: transparent;
  transition: all 0.5s;
`;

const Heading = styled.h2`
  color: #fff;
  margin-bottom: 0;
`;