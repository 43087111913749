import React, { Component } from 'react';
import PropTypes from 'prop-types';
import UIkit from 'uikit';

export class Slider extends Component {
  render() {
  
    const { className, theme, children, sliderInnerClasses, dotsNav, nav, navClasses, ...other } = this.props;

    //UIkit.slider('.slider');

    const NextPrev = () => {

        if (nav) {
            return(
                <>
                  <a className={`uk-position-center-left ${navClasses}`} href="#" uk-slidenav-previous="true" uk-slider-item="previous"></a>
                  <a className={`uk-position-center-right ${navClasses}`} href="#" uk-slidenav-next="true" uk-slider-item="next"></a>
                </>
            );
        }

        return(''); 
    } 

    return (
         <div className={`slider uk-position-relative uk-visible-toggle uk-${theme} ${className}`} tabIndex="-1" uk-slider="true" {...other}>
            <ul className={`uk-slider-items uk-grid ${sliderInnerClasses}`}>
                {children}
            </ul>

            <NextPrev />
            
            {dotsNav ? <div className="uk-slider-nav uk-dotnav uk-flex-center uk-margin"><ul className="uk-slider-nav uk-dotnav"></ul></div> : ''}

        </div>   

    );
  }
}
 
export default Slider;


Slider.propTypes = {
  theme: PropTypes.string,
  className: PropTypes.string,
  sliderInnerClasses: PropTypes.string,
  dotsNav: PropTypes.bool,
  navClasses: PropTypes.string
};

Slider.defaultProps = {
  theme: 'dark',
  className: '',
  sliderInnerClasses: 'uk-child-width-1 uk-child-width-1-2@s uk-child-width-1-3@m',
  dotsNav: false,
  navClasses: 'uk-position-small uk-hidden-hover'
};