import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import TipTeaser from '../components/TipTeaser'
import CategoryTeaser from '../components/categoryTeaser'
import Slider from '../components/slider/Slider'
import SliderItem from '../components/slider/SliderItem'

import styled from 'styled-components'
import get from 'lodash/get'
import { graphql } from 'gatsby'
import ogimage from '../images/subscribe-bg.jpg'

import '../styles/uikit.min.css';




class IndexPage extends React.Component {
  render() {

    const featured = get(this, 'props.data.featured.edges');
    const secondary = get(this, 'props.data.secondary.edges');
    const categories = get(this, 'props.data.categories.edges');


    return (
      <Layout>
      <SEO title="Welcome to Snaphints - the home of photography hints and tips" image={ogimage} />

      <Container>
        <h1>Snaphints the home of photography hints & tips</h1>
        <p>Providing fresh photography tips to help you snap great photos!</p>
      </Container>

      <SectionHeading>Fresh tips</SectionHeading>

       <TeaserList className="fresh-tips">
              {featured.map(({ node }) => {
                return (
                  <TeaserListItem key={node.slug}>
                    <TipTeaser tip={node} />
                  </TeaserListItem>
                )
              })}
        </TeaserList>

         <TeaserList>
              {secondary.map(({ node }) => {
                return (
                  <TeaserListItem key={node.slug}>
                    <TipTeaser tip={node} />
                  </TeaserListItem>
                )
              })}
        </TeaserList>

        <SectionHeading>Find tips</SectionHeading>

        <Slider dotsNav theme="light" autoPlay autoplay-interval={2000}>
          {categories.map(({ node }) => {
            return (
              <SliderItem key={node.slug}>
                <CategoryTeaserItem>
                  <CategoryTeaser  cat={node} />
                </CategoryTeaserItem>
              </SliderItem>  
            )
          })}
       </Slider>     

      </Layout>
    )
  }
}

export default IndexPage

export const pageQuery = graphql`
  query FeaturedTipsQuery {
    site {
      siteMetadata {
        title
      }
    }

    featured: allContentfulTip(sort: {fields: createdAt, order: DESC}, limit:2) {
      edges {
        node {
          title
          slug
          timeToRead
          tag
          category {
            name
          }
          image {
            file {
              url
            }
            fluid(maxWidth: 1400, maxHeight: 900, resizingBehavior: SCALE) {
              ...GatsbyContentfulFluid
            }
          }
        }
      }
    }

    secondary: allContentfulTip(sort: {fields: createdAt, order: DESC}, skip:2, limit:3) {
      edges {
        node {
          title
          slug
          timeToRead
          tag
          category {
            name
          }
          image {
            file {
              url
            }
            fluid(maxWidth: 1400, maxHeight: 900, resizingBehavior: SCALE) {
              ...GatsbyContentfulFluid
            }
          }
        }
      }
    }

    categories: allContentfulCategory {
      edges {
        node {
          slug
          name
          image {
            fluid(maxWidth: 700, maxHeight: 450, resizingBehavior: SCALE) {
              ...GatsbyContentfulFluid
            } 
          }
        } 
      }
    }
  }
`;

const Container = styled.div`
  margin: 0 20px; 
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  text-align: center;
  color: #ffffff;

  h1 {
    font-size: 35px;
    margin-bottom: 10px;
  }

  @media(max-width: 768px) {
    padding-left: 20px;
  }
`;

const SectionHeading = styled.h2`
    color: #fff;
    margin: 20px 10px;
    text-transform: uppercase;
    font-weight: 300;
    letter-spacing: 2px;
    font-size: 25px;

    &:after {
      content: '';
      border: 1px solid #3c7db6;
      display: inline-block;
      width: 30%;
      margin-left: 10px;
    }
`;

const TeaserList = styled.ul`
  margin: 0;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  &.fresh-tips li { 
    &:first-child {
      &:before {
        content: 'Latest';
        position: absolute;
        z-index: 1;
        background: #3c7db6;
        margin: 10px;
        padding: 5px 15px;
        border-radius: 10px;
        letter-spacing: 0.25em;
        text-transform: uppercase;
        font-size: 14px;
        color: #fff;
      }
    }
  }
`;

const TeaserListItem = styled.li`
  list-style: none;
  flex: 100%;
  margin: 10px; 
  @media(min-width: 500px) {
    flex: 1 0 30%;
  }
`;

const CategoryTeaserItem = styled.div`
  list-style: none;
  flex: 100%;
  @media(min-width: 500px) {
    flex: 1 0 20%;
  }
`;